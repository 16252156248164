import React, { useState } from "react";
import { Dimensions, Text, TouchableOpacity, View, StyleSheet, Linking } from "react-native";

import { Hoverable } from "react-native-web-hover";

import Layout from "../components/Layout";
import { useNavigation } from "@react-navigation/native";
import ReferPatientModal from "../components/modals/ReferPatientModal";
import { REFER_PATIENT_TXT } from "../utils/data";

const PRIMARY_COLOR = "#D8D8D8";

export const Index = () => {
  const [showReferralModal, setShowReferralModal] = useState(false);
  const navigation = useNavigation();
  const navResource = () => navigation.navigate("Resources");
  const navCal = () => navigation.navigate("Calculator");
  const onShowModal = () => setShowReferralModal(true);

  const cardData = [
    {
      title: "Resources",
      desc: "This section covers: BZD basics for both clinicians and patients; information about alternative strategies for insomnia and anxiety (the most common indications for BZD); and information about tapering.",
      onClick: navResource,
    },
    {
      title: "Taper Scheduler",
      desc: "Doing all the math involved in a potential taper can be challenging during a brief return visit. This taper scheduler begins by generating a 12 step taper over 24 weeks, which you can then customize for your patient.",
      onClick: navCal,
    },
    {
      title: "Refer Patient",
      desc: " For patients that you think may benefit from referral to specialty mental health care, this takes you to the SAMHSA Behavioral Health Treatment Services Locator.",
      onClick: onShowModal,
    },
  ];

  return (
    <Layout>
      <View style={styles.mainContent}>
        <View style={styles.titleSection}>
          <Text style={styles.titleText}>Welcome to Benzo Basics!</Text>
          <Text style={styles.titleDescription}>
            Clinicians may wish to reduce and or stop prescribing benzodiazepines (BZD) for some of their patients.
            However, especially for patients who have been prescribed a BZD for years, this can seem a daunting task for
            both clinicians and patients. Think of this resource as a BZD resource clearinghouse that pulls together
            information from a variety of sources to help make these conversations slightly easier, organized into the
            three following sections:
          </Text>
        </View>
        <View style={styles.hoverableBlocks}>
          {cardData.map((card) => (
            <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity
                  style={[styles.hoverableBlock, { backgroundColor: hovered ? PRIMARY_COLOR : "#F2F2F2" }]}
                  onPress={() => card.onClick()}
                >
                  <Text style={styles.blockTitle}>{card.title}</Text>
                  <View style={styles.indicator}></View>
                  <Text style={styles.blockText}>{card.desc}</Text>
                </TouchableOpacity>
              )}
            </Hoverable>
          ))}
        </View>
      </View>
      <ReferPatientModal
        text={REFER_PATIENT_TXT}
        isVisible={showReferralModal}
        onDismiss={() => setShowReferralModal(false)}
        onGoToSite={() => {
          Linking.openURL("https://findtreatment.samhsa.gov/locator?sAddr=48103&submit=Go");
          setShowReferralModal(false);
        }}
      />
    </Layout>
  );
};

const styles = StyleSheet.create({
  mainContent: {
    width: Dimensions.get("window").width * 0.8,
    flex: 1,
    margin: 5,
  },
  titleSection: {
    height: Dimensions.get("window").width > 1000 ? 160 : 320,
    margin: 10,
    justifyContent: "space-between",
    flexDirection: "column",
  },
  titleText: {
    fontWeight: "bold",
    fontSize: 65,
    color: "purple",
  },
  titleDescription: {
    marginVertical: 10,
  },
  hoverableBlocks: {
    flexDirection: Dimensions.get("window").width > 800 ? "row" : "column",
    justifyContent: "space-between",
    margin: 0,
    flexWrap: "wrap",
  },
  hoverableBlock: {
    height: 200,
    // width: Dimensions.get("window").width > 600 ? '48%' : '100%',
    width: Dimensions.get("window").width > 1000 ? 500 : 400,
    marginTop: 10,
    marginLeft: Dimensions.get("window").width > 1000 ? 0 : -10,
    padding: 20,
    borderRadius: 20,
  },
  blockTitle: {
    fontWeight: "bold",
    fontSize: 24,
  },
  indicator: {
    height: 10,
    width: 100,
    marginTop: 10,
    backgroundColor: "purple",
    borderRadius: 10,
  },
  blockText: {
    marginTop: 20,
  },
});
