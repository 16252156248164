import React from "react";
import { View, Text, ScrollView, TouchableOpacity, Linking } from "react-native";
import Modal from "modal-enhanced-react-native-web";

const ResourceModal = ({ isVisible, onDismiss, item }) => (
  <Modal
    isVisible={isVisible}
    onBackdropPress={onDismiss}
    style={{
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <View
      style={{
        height: 500,
        width: 400,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 10,
        justifyContent: "space-between",
      }}
    >
      <View>
        <Text
          style={{
            fontSize: 32,
            fontWeight: "bold",
            margin: 10,
            color: "purple",
          }}
        >
          {item.title}
        </Text>
        <ScrollView style={{ margin: 10 }}>
          <Text style={{ fontSize: 14 }}>{item.abstract}</Text>
        </ScrollView>
      </View>
      <TouchableOpacity
        onPress={() => {
          onDismiss();
          Linking.openURL(item.url);
        }}
      >
        <View
          style={{
            backgroundColor: "purple",
            borderRadius: 20,
            margin: 10,
            width: 150,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "white", fontWeight: "bold", margin: 10 }}>Go to resource</Text>
        </View>
      </TouchableOpacity>
    </View>
  </Modal>
);

export default ResourceModal;
