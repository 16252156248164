import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, Dimensions, Text, TouchableOpacity, View, Linking } from "react-native";
import { REFER_PATIENT_TXT } from "../utils/data";
import ReferPatientModal from "./modals/ReferPatientModal";
const PRIMARY_COLOR = "#D8D8D8";

export const Menu = () => {
  const [showReferralModal, setShowReferralModal] = useState(false);
  const navigation = useNavigation();

  // Define navigation functions using the navigation object
  const navUserCenterDir = () => navigation.navigate("UserCenter");
  const navIndex = () => navigation.navigate("Index");
  const navResource = () => navigation.navigate("Resources");
  const navCal = () => navigation.navigate("Calculator");

  return (
    <View style={styles.menuContainer}>
      <View style={styles.menuBar}>
        <View style={styles.taperologyContainer}>
          <TouchableOpacity onPress={navIndex}>
            <Text style={styles.taperologyText}>Benzo Basics</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.navigationContainer}>
          <TouchableOpacity onPress={navResource} style={styles.navItem}>
            <Text style={styles.navText}>Resources</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={navCal} style={styles.navItem}>
            <Text style={styles.navText}>Taper Scheduler</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setShowReferralModal(true)} style={styles.navItem}>
            <Text style={styles.navText}>Refer Patient</Text>
          </TouchableOpacity>
          <ReferPatientModal
            text={REFER_PATIENT_TXT}
            isVisible={showReferralModal}
            onDismiss={() => setShowReferralModal(false)}
            onGoToSite={() => {
              Linking.openURL("https://findtreatment.samhsa.gov/locator?sAddr=48103&submit=Go");
              setShowReferralModal(false); 
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    width: Dimensions.get("window").width * 0.8,
    shadowColor: "black",
    // shadowOffset: 5,
    borderWidth: 1,
    borderBottomWidth: 6,
    borderColor: PRIMARY_COLOR,
    borderBottomColor: "purple",
    backgroundColor: "white",
    margin: 5,
    borderRadius: 15,
    justifyContent: "center",
    height: Dimensions.get("window").width > 1000 ? 80 : 150,
    marginBottom: 50,
  },
  menuBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: Dimensions.get("window").width > 1000 ? 20 : 5,
  },
  taperologyContainer: {
    justifyContent: Dimensions.get("window").width > 1000 ? "space-between" : "center",
    flex: Dimensions.get("window").width > 1000 ? 0.3 : 0.7,
    alignItems: "center",
    borderColor: "rgba(0,0,0,0)",
    borderRightColor: PRIMARY_COLOR,
    borderWidth: 1,
  },
  taperologyText: {
    margin: Dimensions.get("window").width > 1000 ? 15 : 2,
    fontWeight: "bold",
    fontSize: Dimensions.get("window").width > 1000 ? 35 : 35,
  },
  navigationContainer: {
    flexDirection: Dimensions.get("window").width > 1000 ? "row" : "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flex: 0.7,
    padding: 30,
  },
  navItem: {
    margin: 10,
    flex: 1,
    borderRadius: 5,
  },
  navText: {
    fontWeight: "bold",
    fontSize: Dimensions.get("window").width > 1000 ? 20 : 15,
  },
});

export default Menu;
