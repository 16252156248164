import React from "react";
import { View, Image, TouchableOpacity, StyleSheet } from "react-native";
import { FlatList } from "react-native-web";
import { Card } from "react-native-paper";

const ResourceList = (props) => {
  const { data, onItemPress } = props;

  return (
    <View style={styles.container}>
      <FlatList
        data={data}
        horizontal={true}
        showsHorizontalScrollIndicator={true}
        persistentScrollbar={true}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.touchable}
            onPress={() => {
              onItemPress(item)
            }}
          >
            <Card mode="elevated" style={styles.card}>
              <View style={styles.imageView}>
                <Image style={styles.image} source={{ uri: item.imgURL }} />
              </View>
              <Card.Title style={styles.cardTitle} title={item.title} subtitle={item.subtitle} />
            </Card>
          </TouchableOpacity>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    flex: 1,
  },
  touchable: {
    height: 350,
    width: 350,
    borderRadius: 20,
    marginRight: 10,
    padding: 10,
  },
  card: {
    borderRadius: 20,
  },
  imageView: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  image: {
    flex: 1,
    width: 200,
    height: 200,
    resizeMode: "stretch",
    borderRadius: 20,
  },
  cardTitle: {
    margin: 10,
    fontSize: 15,
  },
});

export default ResourceList;
