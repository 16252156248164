import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import * as Analytics from "expo-firebase-analytics";
import { Calculator } from "./pages/Calculator";
import { Resources } from "./pages/Resource";
import { UserCenter } from "./pages/UserCenter";
import { Index } from "./pages/index";

import "@firebase/firestore";
import "@firebase/storage";
import firebase from "firebase";
import { firebaseConfig } from "./secret";

//Initiate Firebase API
//This is a dependent for the expo-firebase-analytics API
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const Stack = createStackNavigator();
Analytics.setAnalyticsCollectionEnabled(true);

console.log("app init");

export default function App() {
  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();

  return (
    //Use navigation API here
    <NavigationContainer
      ref={navigationRef}
      // theme={{colors:{background:"white"}}}
      onReady={() => (routeNameRef.current = navigationRef.current.getCurrentRoute().name)}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;

        console.log("previousRouteName", previousRouteName);
        console.log("currentRouteName", currentRouteName);

        if (previousRouteName !== currentRouteName) {
          // The line below uses the expo-firebase-analytics tracker
          // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
          // Change this line to use another Mobile analytics SDK
          // Analytics.setCurrentScreen(currentRouteName, currentRouteName);
          // alert(`The route changed to ${currentRouteName}`);
          let eventName = "FROM" + previousRouteName + "TO" + currentRouteName;
          await Analytics.setCurrentScreen(currentRouteName);
          await Analytics.logEvent(eventName, {
            name: "ChangeScreen",
            screen: "Menu",
            purpose: "Opens the internal settings",
          });
        }

        // Save the current route name for later comparision
        routeNameRef.current = currentRouteName;
      }}
    >
      <Stack.Navigator
        initialRouteName="Index"
        screenOptions={{
          headerShown: false,
          headerLeft: null,
        }}
      >
        <Stack.Screen name="Index" component={Index} />
        <Stack.Screen name="Calculator" component={Calculator} />
        <Stack.Screen name="Resources" component={Resources} />
        <Stack.Screen name="UserCenter" component={UserCenter} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
