import * as Analytics from "expo-firebase-analytics";
import React from "react";
import { Dimensions, Image, Linking, Text, View } from "react-native";
import ReferPatientModal from "../components/modals/ReferPatientModal";
import ResourceModal from "../components/modals/ResourceModal";
import ResourceList from "../components/resources/ResourceList";
import ResourceTitle from "../components/resources/ResourceTitle";
import {
  ANXIETY_DATA,
  BENZO_BASIC_CLINICIANS_DATA,
  BENZO_BASIC_PATIENT_DATA,
  INSOMNIA_DATA,
  REFER_PATIENT_TXT,
  TAPER_RESOURCE,
} from "../utils/data";

//This imports the data model for the user login function.
//It's not used in the current version but can be activated if there is a future need.
import { getDataModel } from "../DataModel";
import ResourceHeader from "../components/resources/ResourceHeader";
import Footer from "../components/Footer";
import Layout from "../components/Layout";

//This imports the Google Login component.
//It's not used in the current version but can be activated if there is a future need.
// import { GoogleLogin } from "./googleLogin";

const resourceCategories = [
  { title: "Benzo Basics for Clinicians", data: BENZO_BASIC_CLINICIANS_DATA },
  { title: "Benzo Basics for Patients", data: BENZO_BASIC_PATIENT_DATA },
  { title: "Resources on Insomnia", data: INSOMNIA_DATA },
  { title: "Resources on Anxiety", data: ANXIETY_DATA },
  { title: "Resources on Tapering", data: TAPER_RESOURCE },
];

export class Resources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupItem: { url: "", title: "", abstract: "" },
      isLoginVisibleModal: false,
      isPopupModal: false,
      isReferPopupModal: false,
      currentindex: 0,
    };
    this.dataModel = getDataModel();
    this.listRef = React.createRef();
  }

  //Function to show the refer patient popup.
  //Passed into the menu (menu.js) component.
  //Write Google Analytics functions here if this is the user behavior to track.
  showReferPatientModal = () => {
    this.setState({ isReferPopupModal: true });
  };

  render() {
    return (
      <Layout>
        <ReferPatientModal
          text={REFER_PATIENT_TXT}
          isVisible={this.state.isReferPopupModal}
          onDismiss={() => this.setState({ isReferPopupModal: false })}
          onGoToSite={async () => {
            await Analytics.logEvent("ReferPatient", {
              name: "ReferPatient",
              screen: "Calculator",
            });
            Linking.openURL("https://findtreatment.samhsa.gov/locator?sAddr=48103&submit=Go");
            this.setState({ isReferPopupModal: false });
          }}
        />

        <ResourceModal
          isVisible={this.state.isPopupModal}
          item={this.state.popupItem}
          onDismiss={() => this.setState({ isPopupModal: false })}
        />

        <View
          style={{
            width: Dimensions.get("window").width * 0.8,
            margin: 5,
          }}
        >
          <ResourceHeader />
          {/* Map through the resources */}
          <View style={{ margin: 10 }}>
            {resourceCategories.map((category, index) => (
              <View key={index} style={{ marginTop: index > 0 ? 30 : 10 }}>
                <ResourceTitle title={category.title} length={category.data.length} />
                <ResourceList
                  data={category.data}
                  onItemPress={(item) => {
                    this.setState({ popupItem: item, isPopupModal: true });
                  }}
                />
              </View>
            ))}
          </View>
        </View>
      </Layout>
    );
  }
}
