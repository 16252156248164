// CircleIndicator.js
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export const ResourceTitle = (props) => {
  const {title, length} = props;
// const ResourceTitle = ({ BENZO_BASIC_CLINICIANS_DATA, BENZO_BASIC_PATIENT_DATA, INSOMNIA_DATA, ANXIETY_DATA, TAPER_RESOURCE }) => {
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.headerText}>
          {title}{' '}
          <View style={[styles.circle]}>
            <Text style={[styles.number]}>{length}</Text>
          </View>
        </Text>
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
  circle: {
    borderRadius: 12, 
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'purple', 
  },
  number: {
    color: 'white',
    textAlign: 'center',
    fontSize: 18, 
  },
  container: {
    margin: 10,
  },
  section: {
    marginTop: 30,
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 24,
    color: "purple",
  },
});

export default ResourceTitle;
