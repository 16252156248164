import React from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";

const Footer = () => {
  return (
    <View style={styles.footerContainer}>
      <Text style={styles.footerText}>
        This website was created as part of a project funded by the National Institute on Drug Abuse (R01DA045705) to
        Donovan Maust, MD. {"\n"}
        {"\n"}For questions or comments, please contact <Text style={styles.boldText}>Charity Hoffman, PhD, MSW</Text>
        {"\n"}(project coordinator; charityh@med.umich.edu)
      </Text>

      <Text style={styles.footerDisclaimer}>
        THE SOFTWARE DESIGNER AND PROVIDER, INCLUDING ANY COLLABORATING INSTITUTION(S), INCLUDING THE UNIVERSITY OF
        MICHIGAN, SHALL HAVE NO LIABILITY TO ANY PATIENT OR ANY OTHER PERSON. NO SUCH PERSON OR ENTITY ASSUMES ANY LEGAL
        LIABILITY OR RESPONSIBILITY FOR THE ACCURACY, COMPLETENESS, SUITABILITY OR USEFULNESS OF BENZO BASICS OR RELATED
        INFORMATION. ANY AND ALL LIABILITY ARISING DIRECTLY OR INDIRECTLY FROM THE USE OF THIS APPLICATION IS HEREBY
        DISCLAIMED. THE INFORMATION HEREIN IS PROVIDED "AS IS" AND WITHOUT ANY WARRANTY EXPRESSED OR IMPLIED, INCLUDING
        THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  footerContainer: {
    padding: 15,
    maxWidth: Dimensions.get("window").width * 0.8, 
    alignSelf: 'auto', 
  },
  footerText: {
    fontSize: 12,
    textAlign: "center",
    lineHeight: 14,
    marginHorizontal: 10, 
  },
  footerDisclaimer: {
    fontSize: 12,
    textAlign: "center",
    lineHeight: 14,
    marginVertical: 10, 
    marginHorizontal: 10, 
  },
  boldText: {
    fontWeight: "bold",
  },
});

export default Footer;
