import React from "react";
import {
  Text,
  Linking,
} from "react-native";

export const BENZO_TYPE_DATA = [
  { title: "Alprazolam", id: "Alprazolam", strength: "3, 2, 1, 0.5, 0.25" },
  { title: "Lorazepam", id: "Lorazepam", strength: "2, 1, 0.5" },
  { title: "Clonazepam", id: "Clonazepam", strength: "2, 1, 0.5, 0.25, 0.125" },
  { title: "Diazepam", id: "Diazepam", strength: "10, 5, 2" },
  { title: "Temazepam", id: "Temazepam", strength: "30, 22.5, 15, 7.5" },
];

export const STRENGTHS = {
  Alprazolam: 0.125,
  Lorazepam: 0.25,
  Clonazepam: 0.0625,
  Diazepam: 1,
  Temazepam: 3.75,
};

export const REFER_PATIENT_TXT = (
  <Text style={{ margin: 10 }}>
    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
      Refer a Patient using the Behavioral Health Treatment Services Locator
    </Text>
    {"\n"}
    {"\n"}
    <Text style={{ fontSize: 14 }}>
      Just enter the patient's address or zip code to find mental health or
      substance use treatment facilities in their area.
      {"\n"}
      {"\n"}
      This locator is provided by SAMHSA (the Substance Abuse and Mental Health
      Services Administration).
    </Text>
  </Text>
);


export const TIP_DATA = [
  {
    num: "❶",
    title:
      "During the taper, the patient is relearning that they can function without this medication. Appeal to patients’ wish for MASTERY and CONTROL over their situation.",
    subtitle: (
      <Text>
        <Text style={{ fontSize: 20 }}>{"\u2022"}</Text>This means{" "}
        <Text style={{ fontWeight: "bold" }}>no rescue (prn)</Text> benzo doses!
      </Text>
    ),
  },
  {
    num: "❷",
    title: "Stick with the patient’s current benzo:",
    subtitle: (
      <Text>
        <Text style={{ fontSize: 20 }}>{"\u2022"}</Text>The simplest option is
        to taper using the currently-prescribed benzo rather than switching to a
        long-acting benzo.
      </Text>
    ),
  },
  {
    num: "❸",
    title: "Go slowly!",
    subtitle: (
      <Text>
        <Text style={{ fontSize: 20 }}>{"\u2022"}</Text>No faster than 4 months
        {"\n"}
        <Text style={{ fontSize: 20 }}>{"\u2022"}</Text>Might take over a
        year--and that’s fine!
      </Text>
    ),
  },
  {
    num: "❹",
    title: "Skip the add-ons:",
    subtitle: (
      <Text>
        <Text style={{ fontSize: 20 }}>{"\u2022"}</Text>There is minimal
        evidence to suggest that adding another medication will help with the
        taper.
      </Text>
    ),
  },
  {
    num: "❺",
    title: "Hold off on adding medications to replace the benzo:",
    subtitle: (
      <Text>
        <Text style={{ fontSize: 20 }}>{"\u2022"}</Text>Your patient may have
        been on their benzo for decade and it may not be clear if the symptoms
        that led to starting the benzo are even present anymore. {"\n"}
        <Text style={{ fontSize: 20 }}>{"\u2022"}</Text>Have a look at the other
        resources for anxiety and insomnia.
      </Text>
    ),
  },
  {
    num: "❻",
    title:
      "The taper may lead to a lower dose as opposed to a complete stop--and that’s okay!",
    subtitle: (
      <Text>
        <Text style={{ fontSize: 20 }}>{"\u2022"}</Text>Lower dose = lower risk.
      </Text>
    ),
  },
];

//Text used in the Benzo Basics for Clinicians section. Additional future resource can be added here.
export const BENZO_BASIC_CLINICIANS_DATA = [
  {
    title: "The Ashton Manual",
    trackID: "BENZO_BASIC_CLINICIANS_DATA_1",
    url: "https://drive.google.com/file/d/1UzmrSDUgja3SXrvf-q-_VGLsOZCIggFE/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Ashton_Manual.png",
    abstract:
      "The Ashton Manual was developed by Dr. Heather Ashton, Professor of Clinical Psychopharmacology in England. In 1999, she published a manual on safely and effectively  tapering off of BZDs, called “Benzodiazepines: How They Work And How To Withdraw,” which is now known as the Ashton Manual.",
    subtitle: (
      <Text>
        Tapering Guide for Benzodiazepines {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 58 pages</Text>
      </Text>
    ),
    test: <Text></Text>,
  },
  {
    title: "BZD Discussion Outline",
    trackID: "BENZO_BASIC_CLINICIANS_DATA_2",
    url: "https://drive.google.com/file/d/11Nim4jesfl3odnlrbPCcQWx-4fRKTPhP/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/BZD_discussion.png",
    abstract:
      "This one-pager from the VA Academic Detailing Service is a high-level overview of how to engage your patients about benzodiazepines and discuss a potential taper.",
    subtitle: (
      <Text>
        Discussing Benzodiazepine Discontinuation {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 1 page</Text>
      </Text>
    ),
    test: <Text></Text>,
  },

  {
    title: "Provider BZD Educational Guide",
    trackID: "BENZO_BASIC_CLINICIANS_DATA_4",
    url: "https://drive.google.com/file/d/1fAFHaEhaW5bO9E0lOoM6KtSEASEoOJAd/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Re-evaluating_Use.png?token=AJTYIQEQ4D5STWTHN7CYM3DBRLAES",
    abstract: (
      <Text>
        Re-evaluating the Use of Benzodiazepines is a guide developed by the
        VA’s Academic Detailing Service.
        {"\n"}
        {"\n"}It includes background information about prescribing trends in
        recent years, suggests evidence-based alternatives for those with
        anxiety disorders or insomnia, and specifically addresses risks among
        high-risk populations, including those with dementia, PTSD, and older
        adults. {"\n"}
        {"\n"}
        The full catalog of the VA’s Academic Detailing Resources is
        <Text
          style={{ color: "blue" }}
          onPress={() => {
            Linking.openURL(
              "https://www.pbm.va.gov/PBM/AcademicDetailingService/EducationalMaterialCatalog_Publicsite.pdf"
            );
          }}
        >
          {" "}
          here
        </Text>
        , covering additional topics such as alcohol use disorder, chronic
        obstructive pulmonary disease, and pain/opioid safety.
      </Text>
    ),
    subtitle: (
      <Text>
        VA Clinician's Guide {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 32 page</Text>
      </Text>
    ),
  },
  {
    title: "Bottom-line Prescribing Recommendations",
    trackID: "BENZO_BASIC_CLINICIANS_DATA_5",
    url: "https://drive.google.com/file/d/11NnjtDGlka---4IjnCWcz0xMVi2HGbTt/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/bottom_line.png",
    abstract:
      "These are bottom-line prescribing recommendations from the VA evidence synthesis presented in the Provider BZD Educational Guide, including alternatives for anxiety and insomnia and particular patient groups for whom to avoid prescribing.",
    subtitle: (
      <Text>
        Educational Pamphlet {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 1 page</Text>
      </Text>
    ),
    test: <Text></Text>,
  },
];

//Text used in the Benzo Basics for Patient section. Additional future resource can be added here.
export const BENZO_BASIC_PATIENT_DATA = [
  {
    title: "BZD risk infographic",
    trackID: "BENZO_BASIC_PATIENT_DATA_1",
    url: "https://drive.google.com/file/d/11A2hCA2Tqheb6qwbmIbu-CMT2Brmk_5X/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/QuickStart.png?token=AJTYIQAGE4ZQZBGFGJNFOXLBRLAGS",
    abstract:
      "This is a brief, one-page infographic presenting potentially harms associated with prescription benzodiazepine use that may be useful in discussion with patients and family members.",
    subtitle: (
      <Text>
        1-page Infographic {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 1 page</Text>
      </Text>
    ),
  },
  {
    title: "Empower Brochure",
    trackID: "BENZO_BASIC_PATIENT_DATA_2",
    url: "https://drive.google.com/file/d/1-DmaNSdd4QxU4WDG98gJ3PBNT7U5A_-H/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/EMPOWER_brochure.png?token=AJTYIQG5NFPYDKPAEDH2J73BRLABW",
    abstract: (
      <Text>
        The EMPOWER brochure was developed by Dr. Cara Tannenbaum, a
        geriatrician in Montreal. When this information was sent to older adult,
        long-term benzodiazepine users, 27% discontinued benzodiazepine use by 6
        months later (compared to just 5% in the control group; paper
        <Text
          style={{ color: "blue" }}
          onPress={() => {
            Linking.openURL("https://pubmed.ncbi.nlm.nih.gov/24733354/");
          }}
        >
          {" "}
          here
        </Text>
        , brochure{" "}
        <Text
          style={{ color: "blue" }}
          onPress={() => {
            Linking.openURL(
              "https://www.deprescribingnetwork.ca/s/Sleeping-pills_anti-anxiety-meds_Sedative-hypnotics-1.pdf"
            );
          }}
        >
          here
        </Text>
        ).
      </Text>
    ),

    subtitle: (
      <Text>
        Educational Pamphlet {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 12 page</Text>
      </Text>
    ),
  },
  {
    title: "Patient Brochure",
    trackID: "BENZO_BASIC_PATIENT_DATA_3",
    url: "https://drive.google.com/file/d/12evJljCeRXKl1HjJcHr0-xqMNyozFIZ_/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Patient_Brochure.png",
    abstract:
      "This is an alternative to the EMPOWER brochure developed at the University of Michigan by a team including Dr. Maust. It also presents educational information to patients about potential risks and introduces the concept of a taper.",
    subtitle: (
      <Text>
        Educational Pamphlet {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 10 page</Text>
      </Text>
    ),
  },
];

//Text used in the Resource on Insomnia section. Additional future resource can be added here.
export const INSOMNIA_DATA = [
  {
    title: "Insomnia Coach Sleep App",
    trackID: "INSOMNIA_DATA_1",
    url: "https://mobile.va.gov/app/insomnia-coach",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/InsomniaCoach.png?token=AJTYIQFW7QSJ5AGV5GM3RBDBRQHKW",
    subtitle: (
      <Text>
        Downloadable App for Help with Insomnia {"\n"}
        <Text style={{ fontWeight: "bold" }}>App</Text>
      </Text>
    ),
    abstract:
      "This app was developed by the VA to help manage insomnia and is based on CBT for insomnia. It includes a guided weekly training plan with tips for sleeping and person feedback. Available for free for Apple or Android.",
  },
  {
    title: "Sleepwell",
    trackID: "INSOMNIA_DATA_2",
    url: "http://Mysleepwell.ca",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Sleepwell.png?token=AJTYIQB7YFE6TFHK6KGLNOLBRLIJY",
    subtitle: (
      <Text>
        Website on Sleep Hygiene {"\n"}
        <Text style={{ fontWeight: "bold" }}>Website</Text>
      </Text>
    ),
    abstract: (
      <Text>
        Mysleepwell.ca is an excellent website with a wealth of information
        emphasizing reducing use of sleeping pills, promoting sleep hygiene, and
        introducing patients to CBT for insomnia.{"\n"}
        {"\n"}- A printable sleep hygiene checklist is{" "}
        <Text
          style={{ color: "blue" }}
          onPress={() => {
            Linking.openURL("https://mysleepwell.ca/cbti/hygiene-of-sleep/");
          }}
        >
          here
        </Text>
        .{"\n"}
        {"\n"}- A list of recommended books, websites, and apps is{" "}
        <Text
          style={{ color: "blue" }}
          onPress={() => {
            Linking.openURL(
              "https://mysleepwell.ca/cbti/sleepwell-recommends/"
            );
          }}
        >
          {" "}
          here
        </Text>
        .{"\n"}
      </Text>
    ),
  },
  {
    title: "Sleep Diary",
    trackID: "INSOMNIA_DATA_3",
    url: "https://drive.google.com/file/d/11Ouq1yKZNvZSiCJF21WpA3TGBL7YuBbE/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Sleep_diary.png",
    abstract:
      "While the Insomnia Coach app offers a sleep diary, patients who prefer pen-and-pencil can use this two-week sleep diary from the American Academy of Sleep Medicine to help understand their current sleep habits (or lack thereof).",
    subtitle: (
      <Text>
        Educational Pamphlet {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 1 page</Text>
      </Text>
    ),
  },
  {
    title: "10 Tips to Beat Insomnia",
    trackID: "INSOMNIA_DATA_4",
    url: "https://drive.google.com/file/d/11ONYdmCxHMBhTRQcXI0q62YZ0MTF2WMb/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/10_tips.png",
    abstract:
      "These are sleep hygiene-related tips from Great Britain’s National Health Service.",
    subtitle: (
      <Text>
        Educational Pamphlet {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 2 page</Text>
      </Text>
    ),
  },
];

//Text used in the Resource on Anxiety section. Additional future resource can be added here.
export const ANXIETY_DATA = [
  {
    title: "Breathing Retraining",
    trackID: "ANXIETY_DATA_1",
    url: "https://drive.google.com/file/d/1-Qg3l8bKq0P8Ouinx8FStjPXzA6E-4EQ/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Noun_Breathing.png?token=AJTYIQGNV3YW7IAC7YDDXPLBRGDGQ",
    subtitle: (
      <Text>
        Information Sheet {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 1 page</Text>
      </Text>
    ),
    abstract:
      "This information sheet briefly discusses the role of breathing in anxiety  and guides you through a simple breathing retraining technique that uses breathing patterns to help deal with anxiety.",
  },
  {
    title: "Progressive Muscle Relaxation",
    trackID: "ANXIETY_DATA_2",
    url: "https://drive.google.com/file/d/1-TgIQLhkSCxrct_srB_NZLr7Tf5atolC/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Noun_Relaxation.png?token=AJTYIQHROWM37XHJOQECLI3BRGDD6",
    subtitle: (
      <Text>
        Information Sheet {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 1 page</Text>
      </Text>
    ),
    abstract:
      "Muscle tension is another way that the body can respond to stress. This 1-pager, also developed by the Center for Clinical Interventions, introduces Progressive Muscle Relaxation, to help guide the body through periods of heightened tension.",
  },
  {
    title: "5-4-3-2-1",
    trackID: "ANXIETY_DATA_3",
    url: "https://drive.google.com/file/d/102VxfQr2mgQLgi05EpwEZv8MnvzALTrV/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Noun_54321.png?token=AJTYIQCFFJRIUH3SZMGTCV3BRGCS4",
    subtitle: (
      <Text>
        Infographic {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 1 page</Text>
      </Text>
    ),
    abstract:
      "This is a simple infographic that may help patients through a moment of significant distress.",
  },
  {
    title: "Mindfulness Coach",
    trackID: "ANXIETY_DATA_4",
    url: "https://mobile.va.gov/app/mindfulness-coach",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/mindfulness-coach-app-icon.png?token=AJTYIQGJG2OAJ65HN4LBZGTBRQHHW",
    subtitle: (
      <Text>
        Downloadable App {"\n"}
        <Text style={{ fontWeight: "bold" }}>App</Text>
      </Text>
    ),
    abstract: (
      <Text>
        The VA developed this app, which “The app provides a gradual,
        self-guided training program designed to help you understand and adopt a
        simple mindfulness practice. Mindfulness Coach also offers{"\n"}
        {"\n"}- A library of information about mindfulness{"\n"}- 12
        audio-guided mindfulness exercises{"\n"}- A growing catalog of
        additional exercises available for free download{"\n"}- Goal-setting and
        tracking{"\n"}- A mindfulness mastery assessment to help you track your
        progress over time {"\n"}- Customizable reminders{"\n"}- Access to other
        support and crisis resources.
      </Text>
    ),
  },
  {
    title: "Benzodiazepines & PTSD",
    trackID: "ANXIETY_DATA_5",
    url: "https://drive.google.com/file/d/1nGDPgeWX7PNROEeWlt2YPlVeabf0zlEO/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/PTSD.png",
    abstract:
      "The VA and Department of Defense regularly update treatment guidelines for PTSD. These guidelines provide a “strong against” recommendation for prescribing benzodiazepines to patients with PTSD; this 8-page brochure engages patients on this issue.",
    subtitle: (
      <Text>
        Educational Pamphlet {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 8 pages</Text>
      </Text>
    ),
  },
];

//Text used in the Resource on Tapering section. Additional future resource can be added here.
export const TAPER_RESOURCE = [
  {
    title: "The Ashton Manual (Overview)",
    trackID: "TAPER_RESOURCE_1",
    url: "https://drive.google.com/file/d/10tB-5xFAdLDmiJn9sOIXc1ynGPgLFtvK/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Ashton_Manual_brief.png",
    abstract:
      "Drawn from the Ashton manual, this is a high-level overview of psychological and physiological withdrawal symptoms when someone tapers and/or stops their benzodiazepine (2pp). You should prescribe a slow taper in order to minimize these symptoms.",
    subtitle: (
      <Text>
        Tapering Guide for Benzodiazepines {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 2 pages</Text>
      </Text>
    ),
  },
  {
    title: "The Ashton Manual (Detailed)",
    trackID: "TAPER_RESOURCE_2",
    url: "https://drive.google.com/file/d/10lvnI43fGJiTebPCJ0qHxt0B3gqMAsg6/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/Ashton_Manual.png",
    abstract:
      "This is another section of the Ashton manual, providing a more detailed overview of specific potential symptoms of benzodiazepine withdrawal (7pp).  You should prescribe a slow taper in order to minimize these symptoms.",
    subtitle: (
      <Text>
        Tapering Guide for Benzodiazepines {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 7 pages</Text>
      </Text>
    ),
  },
  {
    title: "Empower Taper Schematic",
    trackID: "BENZO_BASIC_CLINICIANS_DATA_3",
    url: "https://drive.google.com/file/d/1NTLL5ZZYztivDkg5by5LpHtQ4nFoL7Lr/view?usp=sharing",
    imgURL:
      "https://raw.githubusercontent.com/KefanXu/taperologyIMG/main/EMPOWER_Taper.png",
    abstract: (
      <Text>
        The EMPOWER brochure was developed by Dr. Cara Tannenbaum, a
        geriatrician in Montreal. When this information was sent to older adult,
        long-term benzodiazepine users, 27% discontinued benzodiazepine use by 6
        months later (compared to just 5% in the control group; paper
        <Text
          style={{ color: "blue" }}
          onPress={() => {
            Linking.openURL("https://pubmed.ncbi.nlm.nih.gov/24733354/");
          }}
        >
          {" "}
          here
        </Text>
        , brochure{" "}
        <Text
          style={{ color: "blue" }}
          onPress={() => {
            Linking.openURL(
              "https://www.deprescribingnetwork.ca/s/Sleeping-pills_anti-anxiety-meds_Sedative-hypnotics-1.pdf"
            );
          }}
        >
          here
        </Text>
        ).
      </Text>
    ),
    subtitle: (
      <Text>
        Educational Pamphlet {"\n"}
        <Text style={{ fontWeight: "bold" }}>PDF | 1 page</Text>
      </Text>
    ),
  },
];
