import React from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import AntDesign from "react-native-vector-icons/AntDesign";

const ResourceHeader = () => {
  return (
    <View style={styles.headerContainer}>
      <View style={styles.headerTitleContainer}>
        <Text style={styles.headerTitleText}>Resources</Text>
      </View>
      <View style={styles.headerInstructionContainer}>
        <Text style={styles.instructionText}>
          <Text>
            Press <Text style={styles.shiftKey}>SHIFT</Text> to Scroll with Mouse Wheel
          </Text>
          <Text>{"\n"}Or Swipe Left on the TrackPad</Text>
        </Text>
        <AntDesign name="arrowright" size={24} color="black" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    height: 100,
    margin: 10,
    justifyContent: "space-between",
    alignItems: Dimensions.get("window").width > 1000 ? "center" : "flex-start",
    flexDirection: Dimensions.get("window").width > 1000 ? "row" : "column",
  },
  headerTitleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitleText: {
    fontWeight: "bold",
    fontSize: 65,
    color: "purple",
  },
  headerInstructionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  instructionText: {
    fontWeight: "bold",
    marginRight: 20,
  },
  shiftKey: {
    borderWidth: 2,
    borderRadius: 5,
  },
});

export default ResourceHeader;
