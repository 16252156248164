import React from "react";
import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import Modal from "modal-enhanced-react-native-web";

const ReferPatientModal = ({ text, isVisible, onDismiss, onGoToSite }) => (
  <Modal
    isVisible={isVisible}
    onBackdropPress={onDismiss}
    style={{
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <View
      style={{
        height: Dimensions.get("window").width > 1000 ? 300 : 350,
        width: 400,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 10,
        justifyContent: "space-between",
      }}
    >
      <View>{text}</View>
      <TouchableOpacity onPress={onGoToSite}>
        <View
          style={{
            backgroundColor: "purple",
            borderRadius: 20,
            margin: 10,
            width: 150,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "white", fontWeight: "bold", margin: 10 }}>Go to the site</Text>
        </View>
      </TouchableOpacity>
    </View>
  </Modal>
);

export default ReferPatientModal;

{
  /*  This renders the login popup. It's not used in the current version but can be activated if there is a future need. */
}
{
  /* <Modal
    style={{
      justifyContent: "center",
      alignItems: "center",
    }}
    isVisible={this.state.isLoginVisibleModal}
    onBackdropPress={() =>
      this.setState({ isLoginVisibleModal: false })
    }
  >
    {this._renderModalLogin()}
  </Modal> */
}

//This renders the login popup.
//It's not used in the current version but can be activated if there is a future need.
// _renderModalLogin = () => (
//   <View
//     style={{
//       backgroundColor: "white",
//       width: 300,
//       padding: 20,
//       justifyContent: "center",
//       alignItems: "center",
//       borderRadius: 20,
//       borderColor: "rgba(0, 0, 0, 0.1)",
//     }}
//   >
//     <FontAwesome name="user-circle-o" size={32} color="black" />

//     <View
//       style={{
//         flex: 1,
//         // backgroundColor: "red",
//         marginTop: 15,
//         width: "100%",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <GoogleLogin
//         navUserCenter={this.navUserCenterDir}
//         dismissLoginModal={this.dismissLoginModal}
//         entry={this.state.entry}
//         saveSchedule={this.saveSchedule}
//       />
//     </View>
//   </View>
// );
