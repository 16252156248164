// Layout.js
import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import Footer from './Footer';
import Menu from './Menu';

const Layout = ({ children }) => {
  return (
    <View style={styles.container}>
      <Menu  />
      <View style={styles.content}>
        {children}
      </View>
      <Footer />
    </View>
  );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // justifyContent: "space-between", 
        alignItems: "center",
      },
      content: {
        flex: 1, 
        width: Dimensions.get("window").width * 0.8,
        margin: 5,
        justifyContent: "center", 
      }
});

export default Layout;
